import React, { MouseEventHandler } from "react";
import Modal from "components/Modal/_default/Modal";

interface LogoutAlertProps {
  isVisible: boolean;
  onLogout: () => void;
  onClose: MouseEventHandler;
}

const LogoutAlert: React.FC<LogoutAlertProps> = ({
  isVisible,
  onClose,
  onLogout,
}) => {
  return (
    <Modal 
      isVisible={isVisible} 
      onClose={onClose}
    >
      <div className="flex flex-col items-center text-[#0A0A0A] mr-4">
        <div className="font-semibold leading-5 mb-5">
          Are you sure you want logout?
        </div>
        <div className="flex items-center">
          <button
            className="rounded-sm bg-black text-[14px] text-white px-[38px] py-[11px] font-bold"
            id="confirm-logout-button"
            onClick={onLogout}
          >
            Yes
          </button>
          <button
            className="rounded-sm border border-[#0A0A0A] text-[14px] px-[38px] py-[11px] ml-[10px] font-bold"
            id="cancel-logout-button"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutAlert;
